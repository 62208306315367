














































































































































import $ from 'jquery'
import "@/plugins/jquery.hoverdir.js";
import { Component, Vue } from "vue-property-decorator";

@Component({
  components: {},
})
export default class HomeClients extends Vue {
  mounted():void {
    this.setHoverEffect();
  }

  setHoverEffect():void {
    $("#da-thumbs > li").hoverdir({
      //hoverDelay	: 75
    });
  }
}
