












import { Component, Vue } from "vue-property-decorator";
import HomeBanner from "@/components/HomeBanner.vue";
import HomeFeature from "@/components/HomeFeature.vue";
import RecoProducts from "@/components/RecoProducts.vue";
import HomeClients from "@/components/HomeClients.vue";

@Component({
  components: {
    HomeBanner,
    HomeFeature,
    RecoProducts,
    HomeClients,
    
  },
})
export default class Home extends Vue {}
